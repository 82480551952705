<template>
  <el-dialog
    top="12vh"
    width="50vw"
    visible
    append-to-body
    :show-close="false"
    :before-close="close"
    custom-class="rounded billings-to-pay-modal"
    @mousewheel="closeActions()"
  >
    <template #title>
      <div class="p-4 d-flex justify-content-between align-items-top border-bottom">
        <div>
          <h2 class="mb-2">{{ translate(isExecution ? 'executionTitle' : 'documentationTitle') }}</h2>
          <p>{{ supplier.name }}</p>
        </div>
        <Button type="icon" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-4">
      <div v-if="unpaidCredits.length" class="rounded border informative-color p-4 row g-0 mb-5">
        <div class="col d-flex align-items-center gap-2">
          <InformationIcon />
          <p class="fw-bold text-break">
            {{
              translate('unpaidCredits.totalCredits', unpaidCredits.length, {
                count: unpaidCredits.length,
                total: unpaidCreditsTotalAmount,
              })
            }}
          </p>
        </div>
        <div class="col d-flex gap-2 justify-content-end align-items-center">
          <el-checkbox v-model="showCredits" />
          {{ translate('unpaidCredits.showCredits', unpaidCredits.length, { count: unpaidCredits.length }) }}
        </div>
      </div>
      <Table
        :data="tableData"
        :columns="columns"
        custom-class="billings-to-pay-table"
        class="mb-4 border rounded"
        row-click-selection-toggle
        :row-selection.sync="selectedRowIndexes"
        :cell-class="getCellStyle"
      >
        <template #cell-date="{ rowData: { date } }">
          <p>{{ formatDate(date) }}</p>
        </template>
        <template
          #cell-total="{
            rowData: {
              total: { remainderAmount },
            },
          }"
        >
          <p>{{ formatMoney(remainderAmount) }}</p>
        </template>
        <template #cell-invoiceAmount="{ rowData: { invoiceAmount } }">
          <p>{{ formatMoney(invoiceAmount) }}</p>
        </template>
        <template #cell-amountToBePaid="{ rowData: { amountToBePaid } }">
          <p>{{ formatMoney(amountToBePaid) }}</p>
        </template>
        <template #cell-status="{ rowData: { status } }">
          <Tag :type="status === 'paid' ? 'success' : 'info'">
            <template v-if="status === 'paid'">
              {{ translate('table.data.paid') }}
            </template>
            <template v-else-if="status === 'inProgress'">
              {{ translate('table.data.inProgress') }}
            </template>
            <template v-else-if="status === 'unpaid'">
              {{ translate('table.data.notPaidYet') }}
            </template>
            <template v-else>
              {{ translate('table.data.partiallyPaid') }}
            </template>
          </Tag>
        </template>
        <template
          #cell-actions="{
            rowData: {
              billingId,
              total: { remainderAmount },
              amountToBePaid,
              fullyPaid,
            },
            rowIndex,
          }"
        >
          <el-dropdown
            v-if="!fullyPaid || true"
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="handleAction($event, billingId)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button type="icon" class="action-button" :class="{ active: activeActions === rowIndex }" @click.stop>
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item v-if="remainderAmount !== amountToBePaid" :command="ACTION.FULL_PAYMENT">
                <div class="d-flex gap-2 align-items-center">
                  <CompleteIcon width="24" height="24" />
                  <p>{{ translate('actions.fullPayment') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!amountToBePaid || remainderAmount === amountToBePaid"
                :command="ACTION.PARTIAL_PAYMENT"
              >
                <div class="d-flex gap-2 align-items-center">
                  <PartialIcon width="24" height="24" />
                  <p>{{ translate('actions.partialPayment') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </Table>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start">
          <div class="d-flex align-items-start flex-column text-typography-primary">
            <p v-if="billingsNotYetPaidAmount">
              {{ `${billingsNotYetPaid} ${formatMoney(billingsNotYetPaidAmount)}` }}
            </p>
            <p v-if="billingsPaidAmount">{{ `${billingsPaid} ${formatMoney(billingsPaidAmount)}` }}</p>
            <p v-if="billingsInPaymentProgressAmount">
              {{ `${billingsInPaymentProgress} ${formatMoney(billingsInPaymentProgressAmount)}` }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-end flex-column">
          <p>{{ billingsToBePaid }}</p>
          <h2>{{ formatMoney(totalBillingsAmount) }}</h2>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleApplyClick">{{ $t('commons.apply') }}</Button>
      </div>
    </template>
    <PartialPaymentModal
      v-if="billingToPartiallyPay"
      :billing="billingToPartiallyPay"
      @close="billingToPartiallyPay = null"
      @partial-payment="handlePartialPayment"
    />
  </el-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import { pipe, omit, values, flatten } from 'ramda';
import Big from 'big.js';

import { Button, Table, Tag } from '@/modules/core';
import { InformationIcon, KebabIcon, PartialIcon, CompleteIcon, CloseIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../../tools/formatters';
import { usePayments, useBillings, useUnpaidBillings } from '../../compositions';
import PartialPaymentModal from './PartialPaymentModal';

const TABLE_HEADERS = {
  DATE: 'date',
  BILLING_NUMBER: 'billingNumber',
  TOTAL: 'total',
  INVOICE_AMOUNT: 'invoiceAmount',
  AMOUNT_TO_BE_PAID: 'amountToBePaid',
  STATUS: 'status',
  ACTIONS: 'actions',
};

const ACTION = {
  PARTIAL_PAYMENT: 'partialPayment',
  FULL_PAYMENT: 'fullPayment',
};

const buildPaidAmountsByBillingId = (payments) =>
  payments.reduce((acc, currentPayment) => {
    const modifiedAccumulator = { ...acc };
    currentPayment.billingLinks.forEach(({ billingId, amount }) => {
      modifiedAccumulator[billingId] = {
        amount: (modifiedAccumulator[billingId]?.amount || 0) + amount,
        completed: (modifiedAccumulator[billingId]?.completed ?? true) && currentPayment.completed,
      };
    });
    return modifiedAccumulator;
  }, {});

export default {
  components: {
    Table,
    Button,
    InformationIcon,
    Tag,
    KebabIcon,
    PartialIcon,
    CompleteIcon,
    CloseIcon,
    PartialPaymentModal,
  },
  props: {
    billingIds: { type: Array, default: () => [] },
    paymentIds: { type: Array, default: () => [] },
    supplier: { type: Object, required: true },
    reconciliationId: { type: String, required: true },
    selectedBillings: { type: Object, default: () => {} },
    isExecution: { type: Boolean, default: false },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { formatToCurrency } = useCurrency();
    const billingsToOmit = pipe(omit([props.reconciliationId]), values, flatten)(props.selectedBillings);
    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: props.billingIds.filter((billingId) => !billingsToOmit.includes(billingId)),
        businessId: currentTenant.value.id,
      }))
    );
    const { payments, loading: paymentsLoading } = usePayments(
      computed(() => ({ paymentIds: props.paymentIds, businessId: currentTenant.value.id }))
    );
    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: currentTenant.value.id, supplierId: props.supplier.id }))
    );
    const unpaidCredits = computed(() =>
      billingsLoading.value
        ? []
        : unpaidBillings.value.filter(
            ({ totalAmount, billingId }) =>
              Math.sign(totalAmount) === -1 &&
              !billings.value.some((b) => b.id === billingId || billingsToOmit.includes(billingId))
          )
    );
    const { billings: creditBillings, loading: creditBillingsLoading } = useBillings(
      computed(() => ({
        ids: unpaidCredits.value.map(({ billingId }) => billingId),
        businessId: currentTenant.value.id,
      }))
    );
    const showCredits = ref(false);

    const paidAmountsByBillingId = computed(() =>
      buildPaidAmountsByBillingId([
        ...payments.value,
        ...(showCredits.value
          ? unpaidCredits.value.map(({ billingId, paidAmount }) => ({
              billingLinks: [{ billingId, amount: paidAmount ?? 0 }],
              completed: true,
            }))
          : []),
      ])
    );
    const billingsWithPaymentData = computed(() => {
      const credits = creditBillings.value.map((billing) => ({ ...billing, unpaidCredit: true }));
      return [...billings.value, ...(showCredits.value ? credits : [])]
        .map((billing) => {
          const paidAmount = paidAmountsByBillingId.value?.[billing.id]?.amount ?? 0;
          return {
            ...billing,
            date: billing.date,
            paymentData: {
              paidAmount,
              completed: !!paidAmountsByBillingId.value?.[billing.id]?.completed,
              remainderAmount: new Big(billing.totalAmount).div(100).minus(paidAmount).toNumber(),
            },
          };
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    const billingsToPay = ref([]);

    const selectedRowIndexes = computed({
      get: () =>
        billingsToPay.value.map(({ billingId }) =>
          billingsWithPaymentData.value.findIndex((billing) => billing.id === billingId)
        ),
      set: (value) => {
        const newBillingsToPay = value.reduce((toPay, index) => {
          const billing = billingsWithPaymentData.value[index];
          if (billing) toPay.push(billing);
          return toPay;
        }, []);
        billingsToPay.value = newBillingsToPay.map((billing) => {
          const paidAmount = billing.paymentData.paidAmount;
          return (
            billingsToPay.value.find(({ billingId }) => billingId === billing.id) ?? {
              billingId: billing.id,
              amount: new Big(billing.totalAmount).div(100).minus(paidAmount).toNumber(),
              date: billing.date,
            }
          );
        });
      },
    });

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      ACTION,
      billingsWithPaymentData,
      billingToPartiallyPay: ref(null),
      payments,
      paidAmountsByBillingId,
      loading: computed(
        () =>
          billingsLoading.value || paymentsLoading.value || unpaidBillingsLoading.value || creditBillingsLoading.value
      ),
      unpaidCredits,
      formatMoney,
      formatDate,
      selectedRowIndexes,
      billingsToPay,
      activeActions: ref(null),
      showCredits,
    };
  },
  computed: {
    tableData() {
      return this.billingsWithPaymentData.map((billing) => {
        const documentSourceType = billing.eventReferences[0].document?.type;
        const documentNumber = billing.eventReferences[0].document?.documentNumber;
        const billingTypeAndNumber = documentSourceType
          ? `${this.$t(`document.exports.schema.type.shortName.${documentSourceType}`)} ${this.$t(
              'document.exports.schema.fields.documentNumber'
            )} ${documentNumber}`
          : null;

        const paymentInProgress = this.paidAmountsByBillingId[billing.id]
          ? !this.paidAmountsByBillingId[billing.id]?.completed
          : false;

        let paymentStatus = 'inProgress';
        if (!paymentInProgress) {
          if (!billing.paymentData.remainderAmount) paymentStatus = 'paid';
          else {
            paymentStatus =
              billing.paymentData.remainderAmount === billing.totalAmount / 100 ? 'unpaid' : 'partiallyPaid';
          }
        }

        return {
          billingId: billing.id,
          [TABLE_HEADERS.DATE]: billing.date,
          [TABLE_HEADERS.BILLING_NUMBER]: billingTypeAndNumber,
          [TABLE_HEADERS.INVOICE_AMOUNT]: billing.totalAmount / 100,
          [TABLE_HEADERS.TOTAL]: {
            remainderAmount: billing.paymentData.remainderAmount,
            totalAmount: billing.totalAmount / 100,
          },
          [TABLE_HEADERS.AMOUNT_TO_BE_PAID]:
            this.billingsToPay.find(({ billingId }) => billingId === billing.id)?.amount ?? null,
          [TABLE_HEADERS.STATUS]: paymentStatus,
          fullyPaid: !billing.paymentData.remainderAmount,
          selectionDisabled: !billing.paymentData.remainderAmount,
          paymentInProgress: paymentInProgress,
          unpaidCredit: billing.unpaidCredit,
        };
      });
    },
    billingsToBePaid() {
      const numOfBillings = this.billingsToPay.length;
      return this.translate('footer.billingsToBePaid', numOfBillings, { count: numOfBillings });
    },
    unpaidBillings() {
      return this.billingsWithPaymentData
        .filter((billing) => billing.paymentData.remainderAmount)
        .map((billing) => billing.paymentData.remainderAmount);
    },
    unpaidCreditsTotalAmount() {
      const totalAmount = this.unpaidCredits.reduce((sum, { unpaidAmount }) => sum + Math.abs(unpaidAmount), 0);
      return this.formatMoney(totalAmount);
    },
    billingsNotYetPaid() {
      const numOfUnpaidBillings = this.unpaidBillings.length;
      return this.translate('footer.billingsNotPaidYet', numOfUnpaidBillings, { count: numOfUnpaidBillings });
    },
    billingsNotYetPaidAmount() {
      return this.unpaidBillings.reduce((sum, remainderAmount) => sum + remainderAmount, 0);
    },
    billingsPaid() {
      const numOfBillings = this.billingsWithPaymentData.filter((billing) => billing.paymentData.completed).length;
      return this.translate('footer.billingsPaid', numOfBillings, { count: numOfBillings });
    },
    billingsPaidAmount() {
      return this.billingsWithPaymentData
        .filter((billing) => billing.paymentData.completed)
        .reduce((sum, billing) => sum + billing.paymentData.paidAmount, 0);
    },
    totalBillingsAmount() {
      return this.billingsToPay.reduce((sum, { amount }) => sum + amount, 0);
    },
    billingsInPaymentProgress() {
      const numOfBillings = Object.values(this.paidAmountsByBillingId).filter(({ completed }) => !completed).length;
      return this.translate('footer.billingsInPaymentProgress', numOfBillings, { count: numOfBillings });
    },
    billingsInPaymentProgressAmount() {
      return Object.values(this.paidAmountsByBillingId)
        .filter(({ completed }) => !completed)
        .reduce((sum, { amount }) => sum + amount, 0);
    },
    columns() {
      return [
        {
          header: this.translate('table.headers.date'),
          key: TABLE_HEADERS.DATE,
        },
        {
          header: this.translate('table.headers.billingNumber'),
          key: TABLE_HEADERS.BILLING_NUMBER,
        },
        {
          header: this.translate('table.headers.invoiceAmount'),
          key: TABLE_HEADERS.INVOICE_AMOUNT,
        },
        {
          header: this.translate('table.headers.total'),
          key: TABLE_HEADERS.TOTAL,
        },
        {
          header: this.translate(this.isExecution ? 'table.headers.amountToBePaid' : 'table.headers.amountPaid'),
          key: TABLE_HEADERS.AMOUNT_TO_BE_PAID,
        },
        {
          header: this.translate('table.headers.status'),
          key: TABLE_HEADERS.STATUS,
        },
        ...(!this.isExecution ? [{ header: '', key: TABLE_HEADERS.ACTIONS }] : []),
      ];
    },
  },
  methods: {
    getCellStyle(_, columnIndex) {
      const colIndex = columnIndex - 1; // without selection column
      const styles = [];
      const indexOfActions = this.columns.findIndex(({ key }) => key === TABLE_HEADERS.ACTIONS);
      if (indexOfActions !== -1 && indexOfActions === colIndex) styles.push('p-0');
      return styles.join(' ');
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    closeActions() {
      if (this.activeActions !== null) {
        this.activeActions = null;
      }
    },
    handlePartialPayment({ billingId, amount, date }) {
      const partiallyPaidBillingIndex = this.billingsToPay.findIndex((billing) => billing.billingId === billingId);
      if (partiallyPaidBillingIndex !== -1) this.billingsToPay[partiallyPaidBillingIndex].amount = amount;
      else this.billingsToPay = [...this.billingsToPay, { billingId, amount, date }];
      this.billingToPartiallyPay = null;
    },
    handleAction(command, billingId) {
      const billing = this.billingsWithPaymentData.find((b) => b.id === billingId);

      switch (command) {
        case ACTION.PARTIAL_PAYMENT:
          this.billingToPartiallyPay = billing;
          break;
        case ACTION.FULL_PAYMENT:
          // eslint-disable-next-line no-case-declarations
          const partiallyPaidBillingIndex = this.billingsToPay.findIndex((b) => b.billingId === billingId);
          if (partiallyPaidBillingIndex !== -1)
            this.billingsToPay[partiallyPaidBillingIndex].amount = billing.paymentData.remainderAmount;
          else
            this.billingsToPay = [
              ...this.billingsToPay,
              { billingId, amount: billing.paymentData.remainderAmount, date: billing.date },
            ];
          break;
        default:
          break;
      }
    },
    handleApplyClick() {
      this.$emit('apply', this.billingsToPay);
    },
    close() {
      this.$emit('close');
    },
    translate(key, count, args = null) {
      return args
        ? this.$tc(`payment.billingsToPaySelectionModal.${key}`, count, args)
        : this.$t(`payment.billingsToPaySelectionModal.${key}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .billings-to-pay-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
.informative-color {
  background-color: change-color($color: $informative, $lightness: 98.1%);
}

.billings-to-pay-table {
  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }
  }
}
</style>
